import * as Link from "./serviceLink";

export const basePath = "";

export const PATH = {
  HOME: `${basePath}/home`,

  /** 신학기 **/
  NEW_SEMESTER: `${basePath}/new-semester`,

  /** 솔루션 **/
  SOLUTION: `${basePath}/solution`,
  SOLUTION_NURSERY: `${basePath}/solution/nursery`,
  SOLUTION_KINDERGARTEN1: `${basePath}/solution/kindergarten`,
  SOLUTION_AREA_CHILDREN_CENTER: `${basePath}/solution/area_children_center`,

  /** 제품소개 **/
  PRODUCT: `${basePath}/product`,
  PRODUCT_IERP: `${basePath}/product/ierp`,
  PRODUCT_IOGAM: `${basePath}/product/iogam`,
  PRODUCT_ONEKIZ: `${basePath}/product/onekiz`,
  PRODUCT_THERMOMETER: `${basePath}/product/thermometer`,

  /** 이용요금 **/
  CHARGE: `${basePath}/charge`,
  CHARGE_ESTIMATE: `${basePath}/charge/estimate`,
  CHARGE_APPLICATION: `${basePath}/charge/application`,

  /** 고객센터 **/
  CUSTOMER: `${basePath}/customer`,
  CUSTOMER_NOTICE: `${basePath}/customer/notice`,
  CUSTOMER_NOTICE_DETAIL: `${basePath}/customer/notice/detail`,
  CUSTOMER_FAQ: `${basePath}/customer/faq`,
  CUSTOMER_FAQ_DETAIL: `${basePath}/customer/faq/detail`,
  CUSTOMER_INQUIRY: `${basePath}/customer/inquiry`,
  CUSTOMER_INQUIRY_DETAIL: `${basePath}/customer/inquiry/detail`,
  CUSTOMER_REMOTE_SUPPORT: `${basePath}/customer/remote_support`,

  /** 회사소개 **/
  COMPANY: `${basePath}/company`,
  COMPANY_INTRO: `${basePath}/company/intro`,
  COMPANY_MAP: `${basePath}/company/map`,
};

export const NEW_SEMESTER_PATH = [
  { label: "신학기 FAQ", path: PATH.NEW_SEMESTER },
];

export const SOLUTION_PATH = [
  { label: "어린이집", path: PATH.SOLUTION_NURSERY },
  { label: "돌봄교실/지역아동", path: PATH.SOLUTION_AREA_CHILDREN_CENTER },
  { label: "유치원/어학원", path: PATH.SOLUTION_KINDERGARTEN1 },
];

export const PRODUCT_PATH = [
  { label: "아이알피", path: PATH.PRODUCT_IERP },
  { label: "아이오감", path: PATH.PRODUCT_IOGAM },
  { label: "오네키즈", path: PATH.PRODUCT_ONEKIZ },
  { label: "스마트 체온계", path: PATH.PRODUCT_THERMOMETER },
];

export const CHARGE_PATH = [
  { label: "아이알피 견적서", path: PATH.CHARGE_ESTIMATE },
  { label: "견적서 신청", path: PATH.CHARGE_APPLICATION },
];

export const CUSTOMER_PATH = [
  { label: "공지사항", path: PATH.CUSTOMER_NOTICE },
  { label: "FAQ", path: PATH.CUSTOMER_FAQ },
  { label: "1:1문의", path: PATH.CUSTOMER_INQUIRY },
  { label: "원격지원", path: PATH.CUSTOMER_REMOTE_SUPPORT },
];

export const COMPANY_PATH = [
  { label: "회사소개", path: PATH.COMPANY_INTRO },
  { label: "본사/지사", path: PATH.COMPANY_MAP },
];

export const QUICKMENU_PATH = [
  {
    key: "inquiry",
    label: "간편문의",
    quickLabel: "간편문의",
    path: "#footer",
    target: false,
  },
  {
    key: "write",
    label: "신청서 작성",
    quickLabel: "신청서",
    path: `${Link.serviceLink}/write_step01.php`,
    target: true,
  },
  {
    key: "store",
    label: "아이알피 스토어",
    quickLabel: "스토어",
    path: `${Link.storeLink}`,
    target: true,
  },
  {
    key: "a/s",
    label: "A/S접수",
    quickLabel: "A/S접수",
    path: `${Link.serviceLink}/inquiry_write01.php`,
    target: true,
  },
  {
    key: "estimate",
    label: "결제하기",
    quickLabel: "결제하기",
    path: `${Link.serviceLink}/pay`,
    target: true,
  },
];

type Keys = keyof typeof PATH;
export type RoutePath = (typeof PATH)[Keys];
