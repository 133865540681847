import styled from "@emotion/styled";

type Props = {
  depthShow: boolean;
};

export const Wrapper = styled.li<Props>`
  position: relative;

  &.emphasis {
    border: 1px solid var(--ierp-color-black);
    border-radius: 10px;
  }

  &.emphasis:hover {
    border: 1px solid var(--ierp-color-primary);
  }

  &.disabled {
    display: none;
    pointer-events: none;

    a {
      color: var(--ierp-text-secondary04);
    }
  }

  > a {
    display: block;
    padding: 6px 15px;
    font-size: 16px;
    white-space: nowrap;
  }

  > a:hover {
    color: var(--ierp-color-primary);
  }

  .navActive {
    color: var(--ierp-color-primary);
  }

  .emphasis {
    border: 1px solid rgba(51, 102, 255, 0.2);
    border-radius: 10px;
    font-weight: 500;
    color: var(--ierp-color-primary);
  }

  .emphasis:hover,
  .emphasis.navActive {
    border: 1px solid var(--ierp-color-primary);
    background-color: var(--ierp-color-primary);
    color: var(--ierp-color-white);
  }
`;
