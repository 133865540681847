import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import SubDepthItem from "../sub-depth-item";
import SubDepthList from "../sub-depth-list";

import * as S from "./NavItem.styled";

type NavSubDepth = {
  label: string;
  path: string;
};

type Props = {
  label: React.ReactNode;
  path: string;
  subDepth?: NavSubDepth[];
  emphasis?: boolean;
  disabled?: boolean; // 이용요금, 고객센터 추가시 삭제
};

function NavItem({ label, path, subDepth, emphasis, disabled }: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [subDepthShow, setSubDepthShow] = useState<boolean>(false);

  useEffect(() => {
    if (pathname === path) {
      if (subDepth) navigate(subDepth[0]?.path);
    }
  }, [navigate, path, pathname, subDepth]);

  return (
    <S.Wrapper
      className={`
        ${disabled ? "disabled" : undefined}
      `}
      onMouseEnter={() => setSubDepthShow(true)}
      onMouseLeave={() => setSubDepthShow(false)}
      depthShow={subDepthShow}
    >
      <NavLink
        to={path}
        className={({ isActive }) =>
          `${isActive ? "navActive" : ""} ${emphasis ? "emphasis" : ""}`
        }
      >
        {label}
      </NavLink>
      {subDepth && (
        <SubDepthList depthShow={subDepthShow}>
          {subDepth.map((item) => (
            <SubDepthItem
              key={`${item.path + item.label}`}
              label={item.label}
              path={item.path}
            />
          ))}
        </SubDepthList>
      )}
    </S.Wrapper>
  );
}

export type { Props as NavItemProps };

export default NavItem;
